<template>
  <div class="py-14">
    <div class="container">
      <div class="mb-12  flex flex-wrap items-end justify-between">
        <div>
          <UiCategoryLabel
            label="Inspiratie"
            icon-disabled
          />
          <div class="max-w-xl">
            <h2>Bekijk originele uitvaart ideeën</h2>
            <p>“Afscheid nemen bestaat wel” is onze slogan. Er is namelijk voor iedereen een passend afscheid mogelijk én nodig. Welke wensen iemand ook heeft. Of een uitvaart nu gebaseerd is op geloof of een bijzondere voorkeur van een overledene – alles is mogelijk. </p>
          </div>
        </div>
        <NuxtLink
          :to="{name: 'artikelen'}"
          class="text-sm font-medium tracking-wide text-primary-500 underline"
        >
          Bekijk alle artikelen
        </NuxtLink>
      </div>
    </div>
    <div class="overflow-hidden">
      <div class="container">
        <div class="container-extend container-inset">
          <Swiper
            class="!overflow-visible"
            :modules="[SwiperMousewheel, SwiperAutoplay, SwiperNavigation]"
            :navigation="{
              nextEl: '.swiper-next',
              prevEl: '.swiper-prev',
            }"
            :mousewheel="{
              forceToAxis: true,
            }"
            :slides-per-view="1"
            :breakpoints="{
              600: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 'auto',
              }
            }"
          >
            <SwiperSlide
              v-for="category in featuredCategories"
              :key="category.id"
              class="pr-5"
            >
              <ArticleCategoryCard :category="category" />
            </SwiperSlide>
            <div class="mx-auto mt-6 flex max-w-[15.625rem] items-center justify-between gap-5">
              <button
                type="button"
                class="swiper-arrow swiper-prev"
              >
                <UiIcon
                  name="ArrowRight"
                  class="rotate-180"
                  size="small"
                />
              </button>
              <button
                type="button"
                class="swiper-arrow swiper-next"
              >
                <UiIcon
                  name="ArrowRight"
                  size="small"
                />
              </button>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {Swiper} from 'swiper';
import type {CategoriesQuery} from '~/graphql/graphql';

const props = defineProps<{
  categories: CategoriesQuery['categories']
}>();

const featuredCategories = computed(() => props.categories.filter(category => category.featured));
</script>

<style scoped>
.swiper-slide {
  width: calc(90%);

  @screen sm {
    width: calc(46% + (theme('padding.6') / 2));
  }
}

.swiper-arrow {
  @apply flex h-10 w-10 items-center justify-center rounded-full border border-primary hover:bg-primary active:bg-primary-800 transition hover:text-white text-primary active:text-white;

  &.swiper-button-disabled {
    @apply opacity-50 pointer-events-none;
  }
}
</style>
