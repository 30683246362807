<template>
  <div class="py-14">
    <div class="container grid grid-cols-12">
      <div
        class="md:p-18 col-span-12 rounded-4xl rounded-bl-[5rem] bg-primary p-10 py-6 text-white @container md:col-start-1 md:col-end-10 md:row-start-1 md:row-end-1 md:py-10 md:pl-16 md:pr-40 lg:mr-10 lg:py-16 lg:pl-24"
      >
        <UiCategoryLabel
          label="#trots"
          icon-disabled
        />
        <h2>Persoonlijke ondersteuning bij Uitvaart-Platform: Altijd hier voor jouw vragen en zorgen</h2>
        <p class="text-base">
          Het toegewijde team van Uitvaart-Platform staat altijd voor je klaar om je te ondersteunen. Als je vragen hebt
          of ergens tegenaan loopt, aarzel dan niet om contact met ons op te nemen. We zijn er om je met warmte en zorg
          verder te helpen.
        </p>
        <p>
          Met vriendelijke groet,
        </p>
        <p>
          Team Uitvaart-Platform.nl
        </p>
        <div
          class="inline-block rounded-xl rounded-bl-4xl bg-white p-6 @xs:rounded-bl-[5rem] @xs:p-12 md:hidden md:w-full lg:ml-10"
        >
          <img
            src="~/assets/images/team.jpeg"
            alt="Uitvaart Platform team"
          >
        </div>
      </div>
      <div class="col-span-12 col-start-8 col-end-13 row-start-1 row-end-1 flex items-center @container max-md:hidden">
        <div class="w-full rounded-xl rounded-bl-4xl bg-white p-6 @xs:rounded-bl-[5rem] @xs:p-12 lg:ml-10">
          <img
            src="~/assets/images/team.jpeg"
            alt="Uitvaart Platform team"
            class="max-h-96"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
</script>
