<template>
  <div class="grid-gap grid grid-cols-10">
    <div class="col-span-10 p-6 !pb-0 pt-14 md:col-span-5 md:p-12 md:max-xl:!pr-0 lg:pl-16 lg:pt-16 xl:col-span-6">
      <UiCategoryLabel label="Particulieren" />
      <h1 class="mb-1.5">
        Afscheid nemen bestaat wel
      </h1>
      <div class="md:max-w-md">
        <p>Van informatie en inspiratie tot de nieuwste trends op het gebied van rouw- en uitvaart.</p>
        <p>Wij helpen je graag bij het vinden van de juiste ondernemers voor een uitvaart.</p>
      </div>
    </div>
    <div class="relative -right-6 -top-6 col-span-5 max-md:hidden xl:-right-12 xl:-top-12 xl:col-span-4">
      <UiCta :to="{name: 'account'}">
        <UiCategoryLabel
          label="Bedrijven"
          icon-flipped
        />
        <h2 class="mb-1">
          Ondernemers opgelet
        </h2>
        <p class="text-base">
          Meld je aan om op Uitvaart-Platform.nl jouw diensten aan te bieden
        </p>
      </UiCta>
    </div>
  </div>
  <div class="px-6 pb-14 pt-2 sm:py-8 md:px-12 lg:px-16">
    <FormKit
      type="form"
      :actions="false"
      @submit="submitSearch"
    >
      <div class="flex gap-x-2 gap-y-4 max-lg:flex-col md:gap-x-4 xl:gap-x-6">
        <FormKit
          v-model="filterCategories"
          type="select"
          :options="options"
          placeholder="Alle categorieën"
          inner-class="!rounded-full"
          outer-class="$reset"
        />
        <div class="flex grow gap-x-2 gap-y-4 max-sm:flex-col md:gap-x-4 xl:gap-x-6">
          <div class="flex flex-1 gap-x-2">
            <div class="grow max-sm:w-1/2">
              <FormKit
                v-model="filterCity"
                placeholder="Locatie"
                type="search"
                inner-class="!rounded-full"
                outer-class="$reset"
                prefix-icon="marker"
              />
            </div>
            <div class="max-sm:w-1/2">
              <FormKit
                v-model="filterRadius"
                type="select"
                :options="[{value: null, label: 'Afstand'}, {value: 10, label: '< 10km'}, {value: 25, label: '< 25km'}, {value: 50, label: '< 50km'}]"
                inner-class="!rounded-full"
                outer-class="$reset"
              />
            </div>
          </div>
          <UiButton
            type="submit"
            label="Vind bedrijven"
            class="whitespace-nowrap max-sm:w-full max-sm:py-3.5"
          />
        </div>
      </div>
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
import {useCategoriesQuery} from '~/graphql/graphql';

const filterCategories = ref<string[]>([]);
const filterCity = ref('');
const filterRadius = ref(null);

const {result: categoriesQueryResult} = useCategoriesQuery();
const categories = computed(() => categoriesQueryResult.value?.categories ?? []);

const options = computed(() => {
  const options = [{value: 0, label: 'Alle categorieën'}];
  categories.value.forEach(category => options.push({value: category.id, label: category.name}));

  return options;
});

function submitSearch() {
  navigateTo({
    name: 'bedrijven',
    query: {
      location: filterCity.value,
      category: filterCategories.value,
      radius: filterRadius.value,
    },
  });
}
</script>
