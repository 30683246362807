<template>
  <div class="container py-14">
    <!-- Temporary URL because we can't use IPX -->
    <AppBanner src="/_images/13739/magazine-bg.jpg">
      <div class="relative sm:px-7 sm:py-5">
        <div class="grid-gap grid-cols-10 sm:grid">
          <div class="col-start-1 col-end-5 row-start-1 row-end-1 mt-6 flex items-center max-sm:relative max-sm:z-10 max-sm:-mb-4 sm:-ml-12">
            <img
              class="-mb-10 block max-sm:mx-auto max-sm:px-10"
              src="~/assets/images/uitvaart-inside-magazine.png"
              alt="Uitvaart Inside magazines"
            >
          </div>
          <UiCard
            :transparent="true"
            class="col-start-4 col-end-11 row-start-1 row-end-1"
          >
            <div class="space-y-2.5 p-6 pt-16 @xs:p-14">
              <img
                class="block sm:max-w-xs"
                src="~/assets/images/uitvaart-inside.png"
                alt="Uitvaart Inside"
              >
              <div class="max-w-xl">
                <p>Vol trots presenteren wij aan jou ons glossy uitvaartmagazine Uitvaart-Inside!</p>
                <p>Benieuwd naar het magazine? Bezoek dan snel de website!</p>
              </div>
              <UiButton
                label="Bekijk Uitvaart-Inside.nl"
                to="https://uitvaart-inside.nl"
                target="_blank"
              />
            </div>
          </UiCard>
        </div>
      </div>
    </AppBanner>
  </div>
</template>
