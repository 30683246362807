<template>
  <div>
    <SectionHeroHome
      class="min-h-[45rem] max-w-screen-2xl"
      :image="page.active_image?.url"
    />
    <div
      class="relative after:absolute after:top-32 after:-z-10 after:size-full after:bg-secondary-100 after:content-['']"
    >
      <NuxtPicture
        src="/home-background-decoration.svg"
        class="absolute left-1/2 top-32 block w-screen -translate-x-1/2 -translate-y-full overflow-clip opacity-[27%]"
        :img-attrs="{class: 'w-full max-w-none min-w-[56rem]', alt: page.title}"
      />
      <div class="relative pb-20 pt-10">
        <div class="container">
          <UiCategoryLabel
            icon-disabled
            label="Inspiratie"
          />
          <SectionIntro
            title="Lees alles over uitvaarten en eromheen"
            :link="{to: {name: 'artikelen'}, label: 'Bekijk alle artikelen'}"
          />
        </div>
        <ArticleOverview
          :articles="articles"
          :limit="5"
          :pagination="false"
          view-more
          class="!pt-0 pb-10"
        />
        <SectionMagazine />
        <ArticleCategorySlider :categories="categories" />
        <SectionTextImageBlocks />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {useArticlesQuery, useCategoriesQuery} from '~/graphql/graphql';

definePageMeta({
  layout: 'home',
  middleware: 'sofie-page',
});

const page = useSofiePage();
const img = useImage();

const {result} = useArticlesQuery(() => ({
  per_page: 5,
}));

const articles = computed(() => result.value?.articles?.data ?? []);

const {result: categoriesQueryResult} = useCategoriesQuery();
const categories = computed(() => categoriesQueryResult.value?.categories ?? []);

useServerSeoMeta({
  ogImage: page.value.active_image?.url ? img(page.value.active_image.url) : undefined,
});
</script>
